import router from '@/router';

const auth = {
    namespaced: true,
    state: {
        user: {},
        token: undefined,
    },
    mutations: {
        login(state, payload) {
            state.token = payload;
        },
    },
    actions: {
        login({ commit }, payload) {
            commit('login', payload);
            window.localStorage.setItem('@donativa/token', payload);
            router.push({ name: 'main.home' });
        },
        logout() {
            router.push({ name: 'auth.login' });
        },
    },
    getters: {
        isLogged(state) {
            return !!state.token;
        },
    },
};

export default auth;
